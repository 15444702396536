.kt-portlet {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05);
  box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05);
  background-color: #ffffff;
  margin-bottom: 20px;
  border-radius: 4px;
}
.kt-portlet .kt-portlet__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
  padding: 0 25px;
  border-bottom: 1px solid #ebedf2;
  min-height: 60px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.kt-portlet .kt-portlet__head .kt-portlet__head-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-line-pack: flex-first;
  align-content: flex-first;
}
.kt-portlet .kt-portlet__head .kt-portlet__head-label .kt-portlet__head-title {
  margin: 0;
  padding: 0;
  font-size: 1.2rem;
  font-weight: 500;
  color: #48465b;
}
.kt-portlet
  .kt-portlet__head
  .kt-portlet__head-label
  .kt-portlet__head-title
  small {
  font-weight: 300;
  padding-left: 0.5rem;
  font-size: 1rem;
  color: #74788d;
}

@media (min-width: 1025px) {
  .kt-form--label-right {
    text-align: center;
  }
}

.kt-portlet .kt-portlet__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 25px;
  border-radius: 4px;
}

.kt-section {
  padding: 0;
  margin: 0 0 2rem 0;
}

.kt-section.kt-section--first {
  margin-top: 0;
}

.kt-section .kt-section__title {
  display: block;
  font-size: 1.3rem;
  font-weight: 500;
  padding: 0;
  margin: 0 0 1.75rem 0;
  color: #48465b;
  left: 0;
}

.kt-section .kt-section__title.kt-section__title-sm {
  font-size: 1.1rem;
}
.kt-avatar {
  position: relative;
  display: inline-block;
}
.kt-avatar .kt-avatar__holder {
  width: 120px;
  height: 120px;
  border-radius: 3px;
  background-repeat: no-repeat;
  background-size: cover;
}

.kt-avatar.kt-avatar--outline .kt-avatar__holder {
  border: 3px solid #ffffff;
  -webkit-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);
  background-image: url(./user5.jpg);
}

.kt-avatar .kt-avatar__upload {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  right: -10px;
  top: -10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ffffff;
}

.kt-avatar .kt-avatar__upload input {
  width: 0 !important;
  height: 0 !important;
  overflow: hidden;
  opacity: 0;
}
.kt-avatar .kt-avatar__upload i {
  color: #5d78ff;
  font-size: 0.9rem;
}
.kt-avatar .kt-avatar__upload:hover {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background-color: #5d78ff;
}
.kt-avatar .kt-avatar__upload:hover i {
  color: #ffffff;
}
.kt-avatar .kt-avatar__cancel {
  cursor: pointer;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  text-align: center;
  justify-content: center;
  position: absolute;
  top: auto;
  right: -10px;
  bottom: -5px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #ffffff;
  -webkit-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);
  /* display: none; */
}
.kt-avatar .kt-avatar__cancel i {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  color: #74788d;
  font-size: 0.9rem;
  padding-top: 3px;
}
.kt-avatar .kt-avatar__cancel:hover i {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  color: #ff5d5d;
}
.input-group {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}
.input-group-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.65rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #74788d;
  text-align: center;
  white-space: nowrap;
  background-color: #f7f8fa;
  border: 1px solid #e2e5ec;
  border-radius: 4px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

#profile .form-control {
  background-color: #f3f6f9 !important;
  border-color: #f3f6f9 !important;
  color: #3f4254 !important;
}

#profile .form-control:focus {
  background-color: #ebedf3 !important ;
  border-color: #ebedf3 !important;
  color: #3f4254 !important;
}
