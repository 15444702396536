@tailwind base;
@tailwind components;
@tailwind utilities;

.ant-form-item-explain-error {
  display: none;
}

.color{
  color:#e7eae751
}